export default {
  termId: '',
  term: '',
  confirmationId: '',
  hasCapstone: null,
  scheduleAdmin: {
    searchText: '',
    term: '',
    filter: '',
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: []
    }
  },
  programAdmin: {
    searchText: '',
    term: '',
    filter: [],
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: []
    }
  },
  studyAbroad: {
    showFilter: false,
    filter: {
      status: '',
      term: [],
      major: [],
      partner: [],
      location: [],
      name: ''
    }
  },
  music: {
    admin: {
      section: 'scan',
      term: '',
      terms: [],
      stuGroup: '',
      classLevel: [],
      event: '',
      eventAttended: true,
      instrument: [],
      instructor: []
    }
  }
}
